<template>
	<div>
		<template>
			<v-snackbar :timeout="5000" :value="true" top :color="isError ? 'error' : 'success'" center v-model="snackBar">
				{{ snackBarText }}
			</v-snackbar>
		</template>
		<div v-if="isloading">
			<spinner></spinner>
		</div>

		<!-- search employee -->

		<filter-and-export-component :export-label="'Export Advice'" :page="page" @loading="setLoading"
			@filter-payroll="filterPayroll" @export-data="exportBankAdvice"
			@reset-data="setQuery"></filter-and-export-component>
		<v-data-table fixed-header height="80vh" :headers="headers" :items="bankAdvice" disable-sort
			class="table-rounded elevation-2" :page.sync="page" @page-count="pageCount = $event"
			:items-per-page="itemsPerPage" hide-default-footer>
			<template v-slot:[`item.bankName`]="{ item }">
				{{ item.employee.employeeBankDetails?.bankName }}
			</template>
			<template v-slot:[`item.sortCode`]="{ item }">
				{{ item.employee.employeeBankDetails?.sortCode }}
			</template>
			<template v-slot:[`item.accountNumber`]="{ item }">
				{{ item.employee.employeeBankDetails?.accountNumber }}
			</template>
			<template v-slot:[`item.depositorAccountNumber`]="{ }">{{
				department === "TRN" ? "0090108077491 " : "0090228077491"
			}}</template>
			<template v-slot:[`item.payerName`]="{}">{{
				department === "TRN"
				? "AmaliTech/GIZ Cedi Account"
				: "AmaliTech Cedi Account"
			}}</template>
			<template v-slot:[`item.accountName`]="{ item }">{{
				item.employee.displayName
			}}</template>
			<template v-slot:[`item.instrumentType`]="{}">{{ CR }}</template>
			<template v-slot:[`item.paymentDate`]="{ item }">{{
				item.date | yearMonth
			}}</template>
			<template v-slot:[`item.transDetail`]="{ item }">{{ item.date | yearMonth }} Salary</template>
			<template v-slot:[`item.no`]="{ index }">
				{{ index + 1 + pageNumbering }}
			</template>
			<template v-slot:[`item.netSalary`]="{ item }">
				{{ department === "NSP" ? formatFigure(item.monthlyAllowancePayable) : formatFigure(item.netSalary) }}
			</template>

			<template slot="body.append">
				<tr class="primary--text footer">
					<th id="subtitle" class="subtitle-2">TOTALS</th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2 table-bordered">
						{{ department === "NSP" ? formatFigure(setBankAdviceTotals?.monthlyAllowancePayable) :
							formatFigure(setBankAdviceTotals?.netSalary) }}
					</th>
					<th id="subtitle" class="subtitle-2"></th>
					<th id="subtitle" class="subtitle-2"></th>
				</tr>
			</template>
			<template v-slot:no-data> NO DATA AVAILABLE ! </template>
		</v-data-table>
		<div class="text-center pt-2">
			<v-pagination v-model="page" :length="totalPages" @input="paginatePage()" circle></v-pagination>
		</div>
	</div>
</template>

<script>
import { EXPORT_BANK_ADVICE_EXCEL } from "../../services/graphql/queries/payrollTierQueries";
import moment from "moment";
import helpers from "../../services/helpers";
import FilterAndExportComponent from "./FilterAndExportComponent.vue";

export default {
	mixins: [helpers],
	components: {
		FilterAndExportComponent,
		//Paginate,
	},
	data: () => ({
		CR: "CR",
		dialog: false,
		dialogDelete: false,
		isloading: false,
		isError: true,
		snackBar: false,
		snackBarText: "",
		date: null,
		pageCount: 0,
		itemsPerPage: 20,
		query: null,
		headers: [
			{
				text: "NO.",
				align: "start",
				sortable: false,
				value: "no",
			},
			{ text: "PAYBANKROUTNO", value: "bankName" },
			{ text: "TRUNCATINGRTNO", value: "sortCode" },
			{ text: "ACCOUNT NO", value: "accountNumber" },
			{ text: "DEPOSITORACCT", value: "depositorAccountNumber" },
			{ text: "PAYERNAME", value: "payerName" },
			{ text: "PAYEENAME", value: "accountName" },
			{ text: "INSTRUMENTTYPE", value: "instrumentType" },
			{ text: "AMOUNT", value: "netSalary" },
			{ text: "PRESENTMENTDATE", value: "paymentDate" },
			{ text: "TRANSDETAIL", value: "transDetail" },
		],
		header: [
			"PAYORBANKROUTNO",
			"TRUNCATINGRTNO",
			"ACCOUNTNO",
			"DEPOSITORACCT",
			"PAYERNAME",
			"PAYEENAME",
			"INSTRUMENTTYPE",
			"AMOUNT",
			"PRESENTMENTDATE",
			"TRANSDETAIL",
		],
		details: [],
	}),
	filters: {
		moment: function (date) {
			return moment(date).format("MMM YYYY");
		},
	},
	inject: ["department"],
	computed: {
		bankAdvice() {
			return this.department === "NSP" ? this.$store.getters["payroll/allNspPayroll"] : this.$store.getters["payroll/allPayroll"];
		},
		setBankAdviceTotals() {
			return this.department === "NSP" ? this.$store.getters["payroll/allNspPayrollTotals"] : this.$store.getters["payroll/allPayrollTotals"];
		},
		pageNumbering() {
			if (this.page === 0) {
				return 0;
			} else {
				return this.page * 20 - 20;
			}
		},
		page: {
			get() {
				return this.$store.getters["payroll/currentPage"];
			},
			set(val) {
				return this.$store.commit("payroll/SET_CURRENT_PAGE", val);
			},
		},

		payrollDate() {
			return this.$store.getters["payroll/currentPayrollDate"];
		},

		totalPages() {
			return this.$store.getters["payroll/totalPages"];
		},
	},

	async mounted() {
		if (this.department === "NSP") {
			await this.getNspPayrollTotals();
		} else {
			await this.getPayrollTotals();
		}
		this.isloading = false;
	},
	methods: {
		setLoading(value) {
			this.isloading = value;
		},

		setQuery(value) {
			this.query = value;
		},

		async getPayrollTotals() {
			await this.$store.dispatch("payroll/fetchCurrentPayrollTotals", {
				department: this.department,
				page: this.page,
				yearMonth: this.payrollDate,
			});
		},
		async getNspPayrollTotals() {
			await this.$store.dispatch("payroll/fetchNspTotals", this.payrollDate);
		},

		async getPayrollData() {
			await this.$store.dispatch("payroll/searchPayrollResults", {
				department: this.department,
				query: this.query,
				payload: this.payrollDate,
				page: this.page,
			});
		},

		async filterPayroll(filterData) {
			this.query = filterData.query;
			if(this.department === "NSP"){
				await this.getNspPayrollTotals();
			} else {
				await this.getPayrollTotals();
			}
			this.isloading = false;
		},

		async exportBankAdvice() {
			this.isloading = true;
			this.$apollo
				.query({
					query: EXPORT_BANK_ADVICE_EXCEL,
					variables: {
						startWith: this.department,
						responseType: "blob",
						headers: {
							Accept: "multipart/form-data",
						},
					},
				})
				.then((response) => {
					this.isloading = false;
					const date = moment(this.payrollDate).format("YYYY-MMMM");
					const departmentNames = {
						SC: "SERVICE CENTER",
						TRN: "TRAINING CENTER",
						OP: "OPERATIONS",
						NSP: "NATIONAL SERVICE PERSONNELS"
					};
					const departmentName = departmentNames[this.department];
					const title = `EMPLOYEES BANK ADVICE FOR - ${departmentName} FOR ${date}.xls`;
					const fileLink = response.data.exportBankAdviseExcel;
					const a = Object.assign(document.createElement("a"), {
						href: fileLink,
						download: title,
					});
					a.click();
					a.remove();
				})
				.catch(() => {
					this.isloading = false;
				});
		},

		async paginatePage() {
			this.isloading = true;
			await this.getPayrollData();
			this.scrollToTop();
			this.isloading = false;
		},

		scrollToTop() {
			window.scroll({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		},
	},
};
</script>

<style>
.v-toolbar__title {
	font-weight: bolder;
	font-size: 1.2rem;
}

.export-pension {
	margin-top: 8rem !important;
}
</style>
