<template>
  <div>
    <payroll-header>
      <template v-slot:payroll-title>
        <h3 class=" primary--text ml-2">Management Payroll</h3>
      </template>
      <template v-slot:action-buttons>
        <AproveAuthorizeGenerate></AproveAuthorizeGenerate>
      </template>
    </payroll-header>
    <tabs-wrapper>
    </tabs-wrapper>
  </div>
</template>

<script>
import TabsWrapper from '@/components/layouts/TabsWrapper';
import PayrollHeader from '@/components/layouts/PayrollHeader';
import AproveAuthorizeGenerate from '@/components/AproveAuthorizeGenerate'

export default {
  components: { TabsWrapper, PayrollHeader, AproveAuthorizeGenerate },

  data: () => ({
     department: "MG"
   }),

  provide() {
    return {department: 'MG'}
  },

  computed: {
    currentDepartment(){
      return this.$store.getters["payroll/currentDepartment"]
    }
  },

  created() {
    if(this.department !== this.currentDepartment){
      this.$store.commit('payroll/SET_BONUS_CURRENT_PAGE', 1)
      this.$store.commit('payroll/SET_CURRENT_PAGE', 1)
      this.$store.commit('payroll/SET_CURRENT_DEPARTMENT', this.department)
    }
  },
};
</script>