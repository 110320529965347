<template>
  <div>
    <template>
      <v-snackbar
        :timeout="5000"
        :value="true"
        top
        :color="isError ? 'error' : 'success'"
        center
        v-model="snackBar"
      >
        {{ snackBarText }}
      </v-snackbar>
    </template>
    <div v-if="isloading">
      <spinner></spinner>
    </div>
    <!-- search employee -->

    <filter-and-export-component
      :export-label="'Export TP'"
      :page="page"
      @loading="setLoading"
      @filter-payroll="filterPayroll"
      @export-data="exportTp"
      @reset-data="setQuery"
    ></filter-and-export-component>
    <v-data-table
      v-if="department === 'SC'"
      fixed-header
      height="80vh"
      :headers="headers"
      :items="payroll"
      disable-sort
      class="table-rounded elevation-2"
      :page.sync="page"
      @page-count="pageCount = $event"
      :items-per-page="itemsPerPage"
      hide-default-footer
    >
      <template v-slot:[`item.no`]="{ index }">
        {{ pageNumbering + index + 1 }}
      </template>
      <template v-slot:[`item.displayName`]="{ item }">
        {{ item?.employee?.displayName }}
      </template>
      <template v-slot:[`item.employeeId`]="{ item }">
        {{ item?.employee?.employeeId }}
      </template>
      <template v-slot:[`item.jobTitle`]="{ item }">
        {{ item?.employee?.jobTitle }}
      </template>
      <template v-slot:[`item.sixMonthsSalary`]="{ item }">
        {{
          formatFigure(
            item.teamPerformance == null
              ? 0
              : item?.teamPerformance.sixMonthsSalary
          )
        }}
      </template>
      <template v-slot:[`item.teamPerformance`]="{ item }">
        {{
          formatFigure(
            item.teamPerformance == null ? 0 : item.teamPerformance.tpBonus
          )
        }}
      </template>
      <template v-slot:[`item.excessTp`]="{ item }">
        {{
          formatFigure(
            item.teamPerformance == null
              ? 0
              : item.teamPerformance.excessTpBonus
          )
        }}
      </template>
      <template v-slot:[`item.bonus`]="{ item }">
        {{
          formatFigure(
            item.teamPerformance == null ? 0 : item.teamPerformance.tpTax
          )
        }}
      </template>

      <template slot="body.append">
        <tr class="primary--text footer">
          <th class="subtitle-2" id="total">TOTALS</th>
          <th class="subtitle-2" id=""></th>
          <th class="subtitle-2" id=""></th>
          <th class="subtitle-2 table-bordered" id="bonus"></th>
          <th class="subtitle-2 table-bordered" id="six-month">
            {{ formatFigure(payrollTotal?.sixMonthsSalary) }}
          </th>
          <th class="subtitle-2 table-bordered" id="team-performance">
            {{ formatFigure(payrollTotal?.teamPerformance) }}
          </th>
          <th class="subtitle-2 table-bordered" id="excess-team-performance">
            {{ formatFigure(payrollTotal.excessTeamPerformance) }}
          </th>
          <th class="subtitle-2 table-bordered" id="team-performance-bonus">
            {{ formatFigure(payrollTotal.tpTax) }}
          </th>
        </tr>
      </template>
      <template v-slot:no-data> NO DATA AVAILABLE ! </template>
    </v-data-table>

    <v-data-table
      v-else
      fixed-header
      height="80vh"
      :headers="header"
      :items="payroll"
      disable-sort
      class="table-rounded elevation-2"
      :page.sync="page"
      @page-count="pageCount = $event"
      :items-per-page="itemsPerPage"
      hide-default-footer
    >
      <template v-slot:[`item.no`]="{ index }">
        {{ pageNumbering + index + 1 }}
      </template>
      <template v-slot:[`item.displayName`]="{ item }">
        {{ item?.employee?.displayName }}
      </template>
      <template v-slot:[`item.employeeId`]="{ item }">
        {{ item?.employee?.employeeId }}
      </template>
      <template v-slot:[`item.jobTitle`]="{ item }">
        {{ item?.employee?.jobTitle }}
      </template>
      <template v-slot:[`item.oneYearSalary`]="{ item }">
        {{
          formatFigure(
            item.kpiBonus == null ? 0 : item?.kpiBonus?.annualSalary
          )
        }}
      </template>
      <template v-slot:[`item.teamPerformance`]="{ item }">
        {{ formatFigure(item.kpiBonus == null ? 0 : item.kpiBonus.kpBonus) }}
      </template>
      <template v-slot:[`item.excessTp`]="{ item }">
        {{
          formatFigure(item.kpiBonus == null ? 0 : item.kpiBonus.excessKpiBonus)
        }}
      </template>
      <template v-slot:[`item.bonus`]="{ item }">
        {{ formatFigure(item.kpiBonus == null ? 0 : item.kpiBonus.kpiTax) }}
      </template>

      <template slot="body.append">
        <tr class="primary--text footer">
          <th class="subtitle-2" id="total">TOTALS</th>
          <th class="subtitle-2" id=""></th>
          <th class="subtitle-2" id=""></th>
          <th class="subtitle-2 table-bordered" id="bonus"></th>
          <th class="subtitle-2 table-bordered" id="one-year">
            {{ formatFigure(payrollTotal?.annualSalary) }}
          </th>
          <th class="subtitle-2 table-bordered" id="kpi">
            {{ formatFigure(payrollTotal?.kpiBonus) }}
          </th>
          <th class="subtitle-2 table-bordered" id="excess-kpi">
            {{ formatFigure(payrollTotal.excessKpiBonus) }}
          </th>
          <th class="subtitle-2 table-bordered" id="kpi-bonus">
            {{ formatFigure(payrollTotal.kpiTax) }}

          </th>
        </tr>
      </template>
      <template v-slot:no-data> NO DATA AVAILABLE ! </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="totalPages"
        @input="paginatePage()"
        circle
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import helpers from "../../services/helpers";
import FilterAndExportComponent from "./FilterAndExportComponent.vue";
import { Parser } from "json2csv";
import moment from "moment";

export default {
  components: { FilterAndExportComponent },
  mixins: [helpers],
  inject: ["department"],
  data: () => ({
    dialog: false,
    dialogDelete: false,
    isloading: false,
    isError: true,
    snackBar: false,
    snackBarText: "",
    pageCount: 0,
    itemsPerPage: 20,
    query: "",
    headers: [
      { text: "NO.", align: "start", sortable: false, value: "no" },
      { text: "EMPLOYEE NAME", value: "displayName" },
      { text: "EMPLOYEE ID", value: "employeeId" },
      { text: "JOB TITLE", value: "jobTitle" },
      { text: "SIX MONTHS SALARY (GH₵)", value: "sixMonthsSalary" },
      { text: "TEAM PERFORMANCE (GH₵)", value: "teamPerformance" },
      { text: "EXCESS TEAM PERFORMANCE (GH₵)", value: "excessTp" },
      { text: "BONUS TAX (GH₵ )", value: "bonus" },
    ],
    header: [
      { text: "NO.", align: "start", sortable: false, value: "no" },
      { text: "EMPLOYEE NAME", value: "displayName" },
      { text: "EMPLOYEE ID", value: "employeeId" },
      { text: "JOB TITLE", value: "jobTitle" },
      { text: "ONE YEAR SALARY (GH₵)", value: "oneYearSalary" },
      { text: "KPI (GH₵)", value: "teamPerformance" },
      { text: "EXCESS KPI (GH₵)", value: "excessTp" },
      { text: "BONUS TAX (GH₵ )", value: "bonus" },
    ],
  }),
  computed: {
    pageLoading() {
      return this.$store.getters["payroll/pageLoading"];
    },
    payroll() {

      return this.department === "SC" ? this.$store.getters["teamPerformance/teamPerformance"] : this.$store.getters["teamPerformance/kpi"];

    },
    payrollTotal() {
      return this.$store.getters["payroll/allPayrollTotals"];
    },
    pageNumbering() {
      if (this.page === 0) {
        return 0;
      } else {
        return this.page * this.itemsPerPage - this.itemsPerPage;
      }
    },
    totalPages() {

      return this.$store.getters["teamPerformance/totalPages"];

    },
    page: {
      get() {
        return this.$store.getters["teamPerformance/currentPage"];
      },
      set(val) {
        return this.$store.commit("teamPerformance/SET_CURRENT_PAGE", val);
      },
    },
    payrollDate() {
      return this.$store.getters["payroll/currentPayrollDate"];
    },

    // hide the back pay column when there is no back pay
  },
  async mounted() {
    await this.getPayrollTotals();
    await this.getTpPayroll();
    await this.getKpPayroll();
    this.isloading = false;
  },
  methods: {
    setLoading(value) {
      this.isloading = value;
    },

    setQuery(value) {
      this.query = value;
    },

    async getPayrollTotals() {
      await this.$store.dispatch("payroll/fetchCurrentPayrollTotals", {
        department: this.department,
        page: this.page,
        yearMonth: this.payrollDate,
      });
    },

    async getTpPayroll(){
      await this.$store.dispatch("teamPerformance/getTeamPerformance", {
        size: 20,
        page: this.page,
        yearMonth: this.payrollDate,
        searchName: this.query || "",
      });
    },

    async getKpPayroll(){
      await this.$store.dispatch("teamPerformance/getKpi", {
        size: 20,
        page: this.page,
        yearMonth: this.payrollDate,
        searchName: this.query || "",
        startWith: this.department
      });
    },

    async getPayrollData() {
      await this.$store.dispatch("payroll/searchPayrollResults", {
        department: this.department,
        query: this.query,
        payload: this.payrollDate,
        page: this.page,
        size: 20,
      });
    },
    async filterPayroll(filterData) {
      this.query = filterData.query;
      if(this.department === "SC"){
        await this.getTpPayroll();
      }else{
        await this.getKpPayroll();
      }
      await this.getPayrollTotals();
      this.isloading = false;
    },

    async exportTp() {
      this.isloading = true;
      const date = moment(this.payrollDate).format("YYYY-MMMM");
      const csvContent = this.payroll.map(this.mapItemToCsv);
      const title =
        this.department === "SC"
          ? `Team Performance Report - ${date}.csv`
          : `KPI Report - ${date}.csv`;
      const parser = new Parser();
      const csv = parser.parse(csvContent);
      const blob = new Blob([csv], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = title;
      link.click();
      link.remove();
      this.isloading = false;
    },
    mapItemToCsv(item, index) {
      const commonFields = {
        "No.": index + 1,
        "Employee Name": item?.employee?.displayName,
        "Employee ID": item?.employee?.employeeId,
        "Job Title": item?.employee?.jobTitle,
      };
      if (this.department === "SC") {
        return {
          ...commonFields,
          "Six Months Salary (GH₵)":
            item?.teamPerformance?.sixMonthsSalary || 0,
          "Team Performance (GH₵)": item?.teamPerformance?.tpBonus || 0,
          "Excess Team Performance (GH₵)":
            item?.teamPerformance?.excessTpBonus || 0,
          "Bonus Tax (GH₵)": item?.teamPerformance?.tpTax || 0,
        };
      } else {
        return {
          ...commonFields,
          "One Year Salary (GH₵)": item?.kpiBonus?.annualSalary || 0,
          "KPI (GH₵)": item?.kpiBonus?.kpBonus || 0,
          "Excess KPI (GH₵)": item?.kpiBonus?.excessKpiBonus || 0,
          "Bonus Tax (GH₵)": item?.kpiBonus?.kpiTax || 0,
        };
      }
    },
    async paginatePage() {
      this.isloading = true;
      await this.getPayrollData();
      this.scrollToTop();
      this.isloading = false;
    },

    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
